.center-search-input .ant-input-group {
  position: relative;
}
.center-search-input .ant-input-group .ant-input {
  padding-right: 42px;
}
.center-search-input .ant-input-group .ant-input-group-addon {
  position: absolute;
  left: auto !important;
  right: 46px;
  z-index: 5;
}
.center-search-input .ant-input-group .ant-input-group-addon .ant-input-search-button,
.center-search-input .ant-input-group .ant-input-group-addon .ant-input-search-button:hover,
.center-search-input .ant-input-group .ant-input-group-addon .ant-input-search-button:focus {
  background: #fff !important;
  border: none !important;
  color: #adb5bd !important;
  outline: none !important;
}
.center-search-input .ant-input-group .ant-input-group-addon .ant-input-search-button:hover,
.center-search-input .ant-input-group .ant-input-group-addon .ant-input-search-button:hover:hover,
.center-search-input .ant-input-group .ant-input-group-addon .ant-input-search-button:focus:hover {
  color: #343a40 !important;
}
