.login-layout {
  min-width: 350px;
  -webkit-transform: translateY(-10%);
  transform: translateY(-10%);
}
.login-layout-body {
  min-height: 250px;
}
.login-layout-body .title {
  font-weight: 700;
  font-size: 2rem;
  color: #2f2a64;
  margin-bottom: 0.3rem;
}
.login-layout-body .des {
  color: #495057;
}
.login-layout-footer {
  border-top: 1px solid #e9ecef;
  color: #6c757d;
}
