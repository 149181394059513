.redirect-container {
  height: 100vh;
  width: 990px;
  margin: 0 auto;
}
.redirect-container .redirect-header {
  padding: 0.5rem 0rem;
  display: flex;
}
.redirect-container .redirect-content {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
}
.redirect-container .redirect-content p,
.redirect-container .redirect-content h1 {
  color: #383176;
}
.redirect-container .redirect-content h1 {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.3;
}
.redirect-container .redirect-footer {
  border-top: 1px solid #e9ecef;
  padding-top: 1rem;
  margin-top: 3rem;
}
