.tab-pane-width.undefined-tabpane-active {
  width: 100%;
}
.ant-tree .ant-tree-treenode {
  padding-bottom: 0.5rem;
  align-items: center;
}
.ant-tree .ant-tree-checkbox {
  margin: 0px 8px 0 0;
}
.ant-tree .fo-all .ant-tree-checkbox-inner {
  width: 32px;
  height: 32px;
}
.ant-tree .fo-all .ant-tree-checkbox:not(.ant-tree-checkbox-indeterminate) .ant-tree-checkbox-inner::after {
  left: 40%;
}
.ant-tree .fo-group {
  padding-top: 1rem;
}
.ant-tree .fo-group .ant-tree-checkbox-inner {
  width: 24px;
  height: 24px;
}
.ant-tree .fo-group .ant-tree-checkbox:not(.ant-tree-checkbox-indeterminate) .ant-tree-checkbox-inner::after {
  left: 30%;
}
.role-tabs-wrap {
  margin-top: 2rem;
}
.role-tabs-wrap .ant-tabs {
  height: calc(100vh - 385px);
}
.role-tabs-wrap .role-cap-tree {
  max-height: calc(100vh - 435px);
  overflow-y: auto;
}
.role-tabs-wrap .ant-tabs-nav-list .ant-tabs-tab:first-child {
  margin-bottom: 16px;
}
.role-tabs-wrap.rtw-new .ant-tabs {
  height: calc(100vh - 260px);
}
.role-tabs-wrap.rtw-new .role-cap-tree {
  max-height: calc(100vh - 310px);
}
