.ant-layout {
  background: transparent;
}
.us-header {
  display: flex;
  min-height: 0;
  height: 48px;
  padding: 0px 1rem 0px 0px;
  line-height: 48px;
  background: #2f2a64;
  justify-content: stretch;
  position: fixed;
  width: 100%;
  z-index: 999;
}
.us-header,
.us-header * {
  box-sizing: border-box;
}
.us-header .center-search {
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.us-header .center-search .auto-complete-con {
  height: 32.5px;
  border-radius: 50px;
  line-height: 0;
}
.us-header .center-search .ant-select-selection,
.us-header .center-search .ant-input {
  border-radius: 50px;
  border-color: #fff;
}
.us-header .center-search .ant-select-selection__placeholder,
.us-header .center-search .ant-select-search__field__placeholder {
  left: 20px;
}
.us-header .center-search .search-ico-con {
  position: absolute;
  height: 32.5px;
  padding-right: 13px;
  z-index: 1;
}
.us-header .center-search .search-ico-con .search-by-con {
  display: flex;
  align-items: center;
  height: 100%;
}
.us-header .center-search .search-ico-con .down-ico {
  font-size: 0.8rem;
  color: #49409A;
}
.us-header .center-search .search-ico-con .search-lbl {
  font-size: 0.7rem;
}
.us-header .logo .ant-badge-count {
  height: 16px;
  line-height: 16px;
}
.us-header .logo .ant-badge-multiple-words {
  padding: 0 5px;
}
.us-header .recept-lbl {
  color: #fff;
}
.us-header .divider-st {
  background-color: #EBEBF2;
}
.us-header .ant-dropdown-link {
  color: #a39dd6;
}
.us-header .ant-dropdown-link:hover {
  color: #fff;
}
.us-header .icon-btn {
  color: #fff;
}
.us-header .icon-header-btn .ant-btn-link {
  color: #fff;
}
.us-header .icon-header-btn .ant-btn-link:hover {
  color: #a39dd6;
}
.us-header .file-tag-color {
  color: #fff;
  background-color: #52c41a;
  border-color: #52c41a;
}
.center-search-dropdown {
  padding: 10px;
}
.center-search-dropdown .ant-select-dropdown-menu {
  max-height: none;
}
.center-search-dropdown .recent-history {
  cursor: default;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #8A8D91;
  font-size: 12px;
}
.center-search-dropdown .recent-history:hover {
  background-color: transparent;
}
.center-search-dropdown .ant-select-item-option.ant-select-item-option-grouped {
  padding-left: 12px;
}
.center-search-dropdown .recent-history-item .rhi-caseno {
  max-width: 100px;
  min-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.center-search-dropdown .list-table-row .list-table-col:nth-child(2) {
  width: 100%;
}
.ant-layout-content {
  margin-top: 48px;
}
.header-dropdown-link {
  color: #fff;
}
.ant-dropdown-trigger {
  cursor: pointer;
}
.header-select-wrap .header-select {
  height: 48px;
  border-right: 1px solid #3b347d;
  border-left: 1px solid #3b347d;
}
.header-select-wrap .header-select .ant-select-selector {
  border: 0px;
  background-color: transparent;
  height: 48px;
}
.header-select-wrap .header-select .ant-select-selector .ant-select-selection-search,
.header-select-wrap .header-select .ant-select-selector .ant-select-selection-placeholder,
.header-select-wrap .header-select .ant-select-selector .ant-select-selection-item {
  color: #a39dd6;
  line-height: 48px;
  opacity: 1;
}
.header-select-wrap .header-select .ant-select-selector .ant-select-selection-item {
  cursor: pointer;
}
.header-select-wrap .header-select .ant-select-selector .ant-select-selection-selected-value {
  color: #a39dd6;
}
.header-select-wrap .header-select .ant-select-arrow {
  color: #a39dd6;
  cursor: pointer;
}
.header-select-wrap .header-select:hover {
  color: #fff;
  background: #272252;
}
.header-select-wrap .header-select:hover .ant-select-arrow,
.header-select-wrap .header-select:hover .ant-select-selection-search,
.header-select-wrap .header-select:hover .ant-select-selection-placeholder,
.header-select-wrap .header-select:hover .ant-select-selection-selected-value {
  color: #fff;
}
.header-select-wrap .header-select.ant-select-open,
.header-select-wrap .header-select .ant-select-focused,
.header-select-wrap .header-select .ant-select-enabled {
  background: #272252;
  color: #fff;
}
.profile-dropdown {
  min-width: 200px;
}
.profile-dropdown .profile-menu-wrap {
  width: 300px;
}
.profile-dropdown .profile-menu-wrap .profile-info {
  width: 208px;
  line-height: 1.3;
}
.header-options {
  display: flex;
  padding: 0px 5px;
  align-items: stretch;
  min-width: 50px;
  align-content: center;
  justify-content: center;
  transition: all 0.3s;
}
.header-options .box-icon {
  color: #a39dd6;
  font-size: 1rem;
}
.header-options .header-options-badge sup {
  background-color: #49409A;
  color: #a39dd6;
  box-shadow: 0px 0px 0px 1px #a39dd6;
}
.header-options:hover .ant-avatar {
  box-shadow: 0px 0px 0px 1px #c4c1e5;
}
.header-options:hover .box-icon {
  color: #c4c1e5;
}
.header-options:hover sup {
  color: #c4c1e5;
  box-shadow: 0px 0px 0px 1px #c4c1e5;
}
.header-options.tenant-wrap {
  line-height: 1.1;
  border-left: 1px solid #403988;
  border-right: 1px solid #403988;
  max-width: 180px;
}
.header-options.tenant-wrap span {
  color: #a39dd6;
}
.ant-dropdown-menu-item {
  padding: 10px 20px;
}
.notification-item {
  width: 420px;
  margin-bottom: 0.3rem;
  position: relative;
  cursor: pointer;
}
.notification-item:last-child {
  margin-bottom: 0rem;
}
.notification-item .noti-icon {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  min-width: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 0.8rem;
  margin-right: 0.5rem;
  position: relative;
}
.notification-item .noti-dec {
  display: grid;
  line-height: 1.2;
  white-space: inherit;
}
.notification-item .noti-dec .ant-typography {
  color: #8A8D91;
}
.notification-item .inv-printed {
  background-color: #4EF2CD;
  border: 2px solid #4EF2CD;
  color: #065443;
}
.notification-item .inv-registered {
  background-color: #fff;
  border: 2px solid #79FF90;
  color: #79FF90;
}
.notification-item .noti-overly {
  opacity: 0;
  transition: all 0.2s;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  background: #fff;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #edecf7 60%);
}
.notification-item:hover .noti-overly {
  opacity: 1;
}
.notification-item .noti-close {
  position: absolute;
  right: 0;
  top: 6px;
  font-size: 10px;
  min-width: 20px;
  width: 20px;
  height: 20px;
}
.notification-item .noti-action {
  display: flex;
  position: absolute;
  bottom: 6px;
}
.notfication-dropdown {
  position: fixed;
  max-height: calc(100vh - 60px);
}
.notfication-dropdown .ant-dropdown-menu {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 83px);
}
.notification-menu .ant-dropdown-menu-item,
.notification-menu .ant-dropdown-menu-submenu-title {
  white-space: inherit;
}
.noti-progress {
  background: linear-gradient(90deg, #2f2a64, #2f2a64 40%, #635caf 60%, #2f2a64);
  background-size: 500% 200%;
  -webkit-animation: progress-animation 2s linear infinite;
  animation: progress-animation 2s linear infinite;
}
@-webkit-keyframes progress-animation {
  0% {
    background-position: 100%;
  }
  100% {
    background-position: 0;
  }
}
@keyframes progress-animation {
  0% {
    background-position: 100%;
  }
  100% {
    background-position: 0;
  }
}
.pulsating-circle:after {
  content: '';
  position: absolute;
  display: block;
  width: 45px;
  height: 45px;
  min-width: 45px;
  box-sizing: border-box;
  margin-left: -1%;
  margin-top: -1%;
  border-radius: 45px;
  background-color: #635caf;
  -webkit-animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}
@-webkit-keyframes pulse-ring {
  0% {
    -webkit-transform: scale(0.33);
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}
@keyframes pulse-ring {
  0% {
    -webkit-transform: scale(0.33);
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}
.tenant-item {
  background-color: #49409A;
  border-radius: 0.3rem;
  padding: 0.2rem;
  font-size: 0.8rem;
  color: #fff;
  height: 32px;
  width: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
