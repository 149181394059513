.day-toggle {
  display: flex;
}
.day-toggle-item {
  background-color: #fff;
  width: 29px;
  height: 29px;
  min-width: 29px;
  color: #000;
  cursor: pointer;
  text-transform: uppercase;
  border-radius: 50%;
  margin-right: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e9ecef;
}
.day-toggle-item:hover,
.day-toggle-item.active {
  background-color: #49409A;
  color: #fff;
  border: 1px solid #e9ecef;
}
.day-toggle-item.disabled {
  background-color: #ced4da;
  color: #fff;
  border: 1px solid #e9ecef;
}
