.case-balance-update .dom-amt-list-table .list-table-header {
  font-weight: 400;
  color: inherit;
  font-size: 1.1em;
}
.case-balance-update .dom-amt-list-table .list-table-header .list-table-col {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dee2e6;
}
.case-balance-update .dom-amt-list-table .list-table-col {
  padding: 0 0.5rem;
}
.case-balance-update .dom-amt-list-table .list-table-row:nth-child(2) .list-table-col {
  padding-top: 1rem;
}
.case-balance-update .dom-amt-list-table .list-table-row .list-table-col:nth-child(1) {
  padding-left: 0;
}
.case-balance-update .dom-amt-list-table .list-table-row .list-table-col:nth-child(6) {
  padding-right: 0;
}
.case-balance-update .dom-amt-list-table .ans-sentence {
  width: 18%;
}
.case-balance-update .dom-amt-list-table .ans-amount {
  width: 12%;
}
.case-balance-update .dom-amt-list-table .ans-agreement .ans-from,
.case-balance-update .dom-amt-list-table .ans-till {
  width: 16%;
}
.case-balance-update .dom-amt-list-table .ans-agreement .ant-input-number-input {
  text-align: right;
}
.case-balance-update .dom-amt-list-table .ans-rate {
  width: 16%;
}
.case-balance-update .dom-amt-list-table .ans-total .list-table-col {
  padding-top: 0.5rem;
  font-size: 1.2em;
}
