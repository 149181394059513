.csql-entity-menu {
  background-color: transparent;
  border-right: none;
}
.csql-entity-menu .ant-menu-item {
  padding: 0rem;
  background-color: transparent;
}
.csql-entity-menu .ant-menu-item:before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'  width='24' height='24' viewBox='0 0 52 52'%3E%3Cpath d='M30.7,17.1c0.1-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.1-0.2,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.1,0-0.1c0-0.1,0-0.1,0-0.1c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.1-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.3l-9.1-9.1c-0.8-0.8-2.1-0.8-2.9,0s-0.8,2.1,0,2.9l5.7,5.7H3c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2h21.2l-5.7,5.7c-0.8,0.8-0.8,2.1,0,2.9c0.4,0.4,0.9,0.6,1.4,0.6c0.5,0,1-0.2,1.4-0.6l9.1-9.1C30.5,17.3,30.6,17.2,30.7,17.1z' fill='%2349409A'/%3E%3C/svg%3E");
  position: absolute;
  right: 0rem;
  top: 0.5rem;
  -webkit-transform: translatex(40px);
  transform: translatex(40px);
  transition: all 300ms;
  opacity: 0;
}
.csql-entity-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
  font-weight: 700;
}
.csql-entity-menu:not(.ant-menu-horizontal) .ant-menu-item-selected:before {
  opacity: 1;
  -webkit-transform: translatex(1px);
  transform: translatex(1px);
}
.csql-entity-menu .ant-menu-item-active {
  background-color: transparent;
}
.csql-entity-menu .ant-menu-item-active:before {
  opacity: 1;
  -webkit-transform: translatex(1px);
  transform: translatex(1px);
}
