.custom-sql .csql-wrap {
  display: flex;
  height: calc(100vh - 130px);
}
.custom-sql .csql-wrap .csql-sidebar {
  background-color: #e9ecef;
  width: 160px;
  padding: 0.6rem 1rem 1rem 1rem;
}
.custom-sql .csql-wrap .csql-sidebar .ant-menu-item-disabled.ant-menu-item-selected,
.custom-sql .csql-wrap .csql-sidebar .ant-menu-submenu-disabled.ant-menu-item-selected {
  color: #49409A !important;
}
.custom-sql .csql-wrap .csql-container {
  width: 100%;
}
.custom-sql .csql-wrap .csql-body {
  flex-grow: 1;
}
.custom-sql .csql-wrap .csql-example {
  margin: 1rem;
  padding: 1rem;
  background-color: #f8f9fa;
}
.custom-sql .csql-wrap .csql-textarea-wrap {
  padding: 0 1rem;
}
.custom-sql .csql-wrap .csql-textarea-wrap .ant-form-item-explain {
  margin-top: 0.5rem;
}
.custom-sql .csql-wrap .csql-textarea {
  height: calc(100vh - 345px) !important;
  width: 100%;
  resize: none;
  padding: 1rem;
}
.custom-sql .csql-wrap .csql-footer {
  display: flex;
  justify-content: flex-end;
  padding: 0 1rem 1rem 1rem;
}
.csql-save-query .csq-table .ant-empty-normal {
  margin: 2px 0;
}
.csql-save-query .csq-table .ant-empty-image {
  margin-bottom: 0;
}
.csql-view-result .ant-avatar {
  background-color: #49409A;
}
