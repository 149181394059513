.role-setup {
  padding: 1rem;
  background-color: #e9ecef;
  border-radius: 5px;
  transition: all 0.2s ease;
}
.role-setup .role-setup-items {
  background-color: #fff;
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  line-height: 1.3;
  transition: all 0.2s ease;
  border: 2px solid #fff;
}
.role-setup .role-setup-items .item-icon-content {
  transition: all 0.5s ease;
  display: flex;
  height: 28px;
  width: 28px;
  border-radius: 100%;
  border: 1px solid #49409a;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.role-setup .role-setup-items .item-icon-content .check-icon {
  color: #fff;
}
.role-setup .role-setup-items .delete-item-space {
  width: 32px;
}
.role-setup .role-setup-items.item-transparent {
  background-color: transparent;
  padding: 0px 0.5rem;
}
.role-setup .role-setup-items:hover {
  background-color: #fff;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.16);
  border: 2px solid #49409a;
  cursor: pointer;
}
.role-setup .role-setup-items.active {
  background-color: #fff;
  border: 2px solid #49409a;
}
.role-setup .role-setup-items.active .item-icon-content {
  background-color: #49409a;
  color: #fff;
}
.creditor-scroll-box {
  border: 1px solid #ccc;
  height: 300px;
  overflow-y: auto;
  padding: 0.5rem;
}
.creditor-scroll-box .ant-checkbox-group {
  display: flex;
  flex-direction: column;
}
.creditor-scroll-box .ant-checkbox-wrapper {
  margin: 0 0 3px 0;
}
.creditor-scroll-box .ant-checkbox-wrapper .ant-checkbox + span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
