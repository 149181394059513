.web-cam-modal .ant-modal-body {
  padding-bottom: 16px;
}
.web-cam-modal .web-cam-container {
  position: relative;
  width: 100%;
  height: 40vh;
  margin-bottom: 16px;
}
.web-cam-modal .web-cam-control {
  display: flex;
  align-items: center;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}
.web-cam-modal .web-cam-control button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  padding: 0;
  font-style: normal;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
}
.web-cam-modal .web-cam-control button[disabled] {
  cursor: default;
}
.web-cam-modal .web-cam-control.zoom button {
  font-size: 18px;
}
.web-cam-modal .web-cam-control.rotate button {
  font-size: 16px;
}
.web-cam-modal .web-cam-control.rotate button:first-of-type {
  -webkit-transform: rotate(-20deg);
  transform: rotate(-20deg);
}
.web-cam-modal .web-cam-control.rotate button:last-of-type {
  -webkit-transform: rotate(20deg);
  transform: rotate(20deg);
}
.web-cam-modal .web-cam-control .ant-slider {
  flex: 1 1;
  margin: 0 8px;
}
