.manage-agreements input {
  text-align: right;
}
.manage-agreements .debtor-agreed {
  padding: 0.8rem 1rem;
  border: 1px solid #52c41a;
  border-radius: 0.3rem;
}
.manage-agreements .debtor-agreed .agreed-icon {
  color: #52c41a;
}
.manage-agreements .debtor-agreed .amount {
  font-size: 1.2em;
  line-height: 1;
}
.manage-agreements .gray-box {
  background-color: #edecf7;
  padding: 0.5rem 1rem;
}
