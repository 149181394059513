.cq-list {
  transition: all 0.5s;
  border-radius: 0.3rem;
  border: 1px solid #e9ecef;
}
.cq-item {
  transition: all 0.3s;
  border-bottom: 1px solid #e9ecef;
}
.cq-item:hover {
  background-color: #edecf7;
}
.cq-item:last-child {
  border-bottom: none;
}
.drag-courser {
  cursor: move;
  cursor: grab;
  cursor: -webkit-grab;
}
.cq-item-dragging {
  background-image: linear-gradient(145deg, #dee2e6 4.55%, #fff 4.55%, #fff 50%, #dee2e6 50%, #dee2e6 54.55%, #fff 54.55%, #fff 100%);
  background-size: 19.18px 13.43px;
  border: 1px solid #dee2e6;
  box-shadow: inset 0 0 10px #f8f9fa;
  transition: all 0.3s;
}
.cq-item-over {
  background-color: #fefadc;
  border: 2px solid #fdee91;
  padding-left: 10px;
  box-shadow: inset 0 0 10px #fdee91;
  transition: all 0.3s;
}
.cq-item-new {
  background-color: #DDF8E3;
  transition: all 0.3s;
  border-bottom: none;
}
.cq-item-new:hover {
  background-color: #c4f3ce;
}
