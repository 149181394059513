.result-container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 2.5rem 0.5rem;
}
.result-container .tab-header {
  margin: 0 3rem;
}
.result-container .count-badge {
  margin-left: 0.5rem;
  background-color: #e9ecef;
  padding: 0 0.5rem;
  text-align: center;
  border-radius: 1rem;
  color: #212529;
  font-size: 90%;
}
.search-results .search-filters {
  position: fixed;
  padding-right: 2rem !important;
  padding-bottom: 2rem !important;
  max-height: calc(100vh - 48px);
  overflow-x: auto;
  max-width: 16.66666667%;
}
@media (min-width: 1600px) {
  .search-results .search-filters {
    max-width: 12.5%;
  }
}
.search-results .search-filters::-webkit-scrollbar {
  display: none;
}
.search-results .result-table {
  max-height: calc(100vh - 280px);
  overflow-x: auto;
  padding-right: 2rem;
}
.search-results .result-table thead {
  display: none;
}
.search-results .result-table .ant-table.ant-table-small .ant-table-tbody > tr > td,
.search-results .result-table .ant-table.ant-table-small .ant-table-thead > tr > th {
  padding: 0.3rem 0;
}
.search-results .result-table td.item-type {
  border-bottom: none;
  vertical-align: top;
}
.search-results .result-table tr.ant-table-row:hover > td {
  background-color: transparent !important;
}
.search-results .result-table.debtor-result-table table .ar-table .ant-table {
  margin-left: 0 !important;
  background-color: transparent !important;
}
.search-results .result-table.debtor-result-table table .ar-table thead {
  display: table-header-group;
}
.search-results .result-table.debtor-result-table table .ar-table thead th {
  background-color: transparent !important;
}
.search-results .result-table.debtor-result-table table .ar-table .ant-table-cell {
  background-color: transparent !important;
}
.search-results .result-table.debtor-result-table .ar-case-list table tr:last-child td {
  padding-bottom: 2rem;
}
.search-results .result-table.creditor-result-table table .cr-table .ant-table {
  margin-left: 0 !important;
  margin-bottom: 0.5rem !important;
}
.search-results .result-table.creditor-result-table table .cr-table .ant-table-tbody > tr > td,
.search-results .result-table.creditor-result-table table .cr-table .ant-table-thead > tr > th {
  border-bottom: none;
  white-space: nowrap;
}
.search-results .result-table.creditor-result-table table .cr-table .ant-table-tbody > tr > td:not(:first-child),
.search-results .result-table.creditor-result-table table .cr-table .ant-table-thead > tr > th:not(:first-child) {
  padding-right: 2rem !important;
}
.search-results .result-table.creditor-result-table table .cr-table thead {
  display: table-header-group;
}
.search-results .result-table.creditor-result-table table .cr-table thead th {
  background-color: transparent !important;
}
.search-results .ant-avatar {
  background-color: #49409A;
  margin-right: 0.3rem;
}
.search-results .title,
.search-results .title h3 {
  color: #49409A;
  cursor: pointer;
}
.search-results label {
  color: #adb5bd;
  margin-right: 0.3rem;
  white-space: nowrap;
  height: auto;
}
.search-results .checkbox-case-open .ant-checkbox-inner {
  background-color: #52c41a;
  border-color: #52c41a;
}
.search-results .checkbox-case-freeze .ant-checkbox-inner {
  background-color: #E7BB2B;
  border-color: #E7BB2B;
}
.search-results .checkbox-case-sentence .ant-checkbox-inner {
  background-color: #41C0E0;
  border-color: #41C0E0;
}
.search-results .checkbox-case-close .ant-checkbox-inner {
  background-color: #f5222d;
  border-color: #f5222d;
}
.search-results .checkbox-case-court .ant-checkbox-inner {
  background-color: #17252E;
  border-color: #17252E;
}
.search-results .checkbox-case-active .ant-checkbox-inner {
  background-color: #52c41a;
  border-color: #52c41a;
}
.search-results .checkbox-case-deactive .ant-checkbox-inner {
  background-color: #ced4da;
  border-color: #ced4da;
}
.search-results .checkbox-case-nextduedate .ant-checkbox-inner {
  background-color: #F7E289;
  border-color: #F7E289;
}
.search-results .checkbox-case-fixedvisit .ant-checkbox-inner {
  background-color: #212529;
  border-color: #212529;
}
.search-results .checkbox-case-unlimited .ant-checkbox-inner {
  background-color: #fa8c16;
  border-color: #fa8c16;
}
.search-results .checkbox-case-mapped .ant-checkbox-inner {
  background-color: #52c41a;
  border-color: #52c41a;
}
.search-results .checkbox-case-exceeded .ant-checkbox-inner {
  background-color: #393939;
  border-color: #393939;
}
.search-results .checkbox-case-returntodebtor .ant-checkbox-inner {
  background-color: #4CAFAD;
  border-color: #4CAFAD;
}
.search-results .checkbox-case-unknown .ant-checkbox-inner {
  background-color: #EECB90;
  border-color: #EECB90;
}
.search-results .ar-table {
  background-color: #e9ecef;
}
.search-results .search-results-close {
  position: fixed;
  top: 65px;
  right: 16px;
  border: none;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.search-results .search-results-close:hover {
  background-color: #f5222d;
  color: #fff;
}
