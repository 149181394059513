.channels-content {
  transition: all 0.2s ease;
}
.item-content {
  transition: all 0.2s ease;
  border: 2px solid #dee2e6;
  background-color: #fff;
  padding: 0.1rem 0.5rem;
  border-radius: 5px;
  height: 50px;
}
.item-content .channel-item-txt {
  font-size: 0.7rem;
  color: #6c757d;
}
.item-content:hover {
  background-color: #fff;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.16);
  border: 2px solid #49409a;
  cursor: pointer;
}
.item-content.noHover {
  pointer-events: none;
}
.item-content .item-icon-content {
  transition: all 0.5s ease;
  display: flex;
  height: 20px;
  width: 20px;
  min-width: 20px;
  border-radius: 100%;
  border: 1px solid #49409a;
  justify-content: center;
  align-items: center;
  color: #49409A;
}
.item-content .item-icon-content .check-icon {
  color: #fff;
}
.item-content.active {
  background-color: #fff;
  border: 2px solid #49409a;
}
.item-content.active .item-icon-content {
  background-color: #49409a;
  color: #fff;
}
