.activity-bar {
  display: flex;
}
.activity-bar .ant-row.ant-form-item {
  margin-bottom: 0;
}
.activity-bar .activity-bar-select {
  position: relative;
}
.activity-bar .activity-bar-select .activity-bar-select-input .ant-select-selection-search-input {
  padding-left: 20px;
  padding-right: 20px;
}
.activity-bar .activity-bar-select .activity-bar-select-input .ant-select-selector {
  padding-left: 30px;
  padding-right: 30px;
}
.activity-bar .activity-bar-select .activity-bar-select-input .ant-select-clear {
  font-size: 14px;
  margin-top: -7px;
  right: 15px !important;
}
.activity-bar .activity-bar-select .activity-bar-select-before {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  padding: 4px 8px;
}
.activity-bar .activity-bar-select .parameters-dropdown-btn {
  position: absolute;
  top: 15px;
  left: 0px;
  z-index: 1;
  padding: 4px 8px;
  pointer-events: none;
}
.parameters-dropdown {
  background-color: #fff;
  padding: 1.5rem;
  box-shadow: 0px 5px 10px 5px rgba(0, 0, 0, 0.15);
}
.parameters-dropdown .parameters {
  z-index: 1;
  position: relative;
}
.parameters-dropdown .parameters .parameters-value > div {
  position: relative;
}
.parameters-dropdown .parameters .parameters-value > div .ant-select {
  margin: 5px 0;
}
.parameters-dropdown .parameters .ant-table {
  background-color: transparent;
}
.parameters-dropdown .parameters .ant-table .ant-table-tbody {
  background-color: #fff;
}
