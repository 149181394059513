.login {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  justify-content: center;
  align-items: center;
  background: #F9F9FA;
  position: relative;
  z-index: 1;
  min-width: 0px;
  flex: 1 1 auto;
}
.login-logo {
  padding-left: 10em;
}
.login .ant-form-item-required::before {
  content: "";
  margin-right: 0px;
}
.login-bg {
  background-attachment: fixed, fixed;
  background-size: calc(((100vw - 55%) / 2) - 32px), calc(((100vw - 80%) / 2) - 32px), cover;
  background-repeat: no-repeat, no-repeat;
  background-position: left bottom, right bottom;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.login-bg.log-bg {
  background-image: url(./login-bg.svg), url(./login-bg-right.svg);
}
.login-bg.fp-bg {
  background-image: url(./forget-pw-bg.svg), url(./login-bg-right.svg);
}
