.activities-layout,
.activities-add .ad-layout {
  display: flex;
  flex-direction: row;
  flex: auto;
  min-height: 0;
}
.activities-layout-side,
.activities-add .ad-layout-side {
  flex: 0 0 340px;
  max-width: 340px;
  min-width: 340px;
  width: 340px;
  border-right: 1px solid #e9ecef;
  padding: 1rem;
  height: calc(100vh - 95px);
  overflow-y: auto;
}
.activities-layout-side {
  flex: 0 0 250px;
  max-width: 250px;
  min-width: 250px;
  padding: 0 1.5rem 80px 1.5rem;
  position: relative;
  height: calc(100vh - 195px);
}
.activities-layout-content {
  padding: 1rem;
  width: 100%;
}
.activities-layout .ant-affix .activities-layout-side {
  height: calc(100vh - 95px);
}
.activities-layout .activitieslist-search {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 1rem 0;
  background-color: #fff;
  z-index: 1;
}
.activities-type-menu {
  border-right: 0px solid transparent;
}
.activities-type-menu .ant-menu-item {
  padding: 0rem;
}
.activities-type-menu .ant-menu-item:before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'  width='24' height='24' viewBox='0 0 52 52'%3E%3Cpath d='M30.7,17.1c0.1-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.1-0.2,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.1,0-0.1c0-0.1,0-0.1,0-0.1c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.1-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.3l-9.1-9.1c-0.8-0.8-2.1-0.8-2.9,0s-0.8,2.1,0,2.9l5.7,5.7H3c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2h21.2l-5.7,5.7c-0.8,0.8-0.8,2.1,0,2.9c0.4,0.4,0.9,0.6,1.4,0.6c0.5,0,1-0.2,1.4-0.6l9.1-9.1C30.5,17.3,30.6,17.2,30.7,17.1z' fill='%2349409A'/%3E%3C/svg%3E");
  position: absolute;
  right: 0rem;
  top: 0.5rem;
  -webkit-transform: translatex(40px);
  transform: translatex(40px);
  transition: all 300ms;
  opacity: 0;
}
.activities-type-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #fff;
  font-weight: 700;
}
.activities-type-menu:not(.ant-menu-horizontal) .ant-menu-item-selected:before {
  opacity: 1;
  -webkit-transform: translatex(1px);
  transform: translatex(1px);
}
.activities-type-menu .ant-menu-item-active {
  background-color: #fff;
}
.activities-type-menu .ant-menu-item-active:before {
  opacity: 1;
  -webkit-transform: translatex(1px);
  transform: translatex(1px);
}
.activities-type-menu .ant-menu-title-content {
  display: flex;
  align-items: center;
}
.activities-type-menu .atm-title {
  width: 100px;
}
.activities-type-menu .atm-count {
  border-color: #dee2e6;
  background-color: #dee2e6;
  border-radius: 10px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 80%;
  min-width: 30px;
  text-align: center;
}
.activities-table .ant-table-row.ant-table-row-level-0 .ant-table-cell {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #f8f9fa;
}
.activities-add .ant-drawer-body {
  padding: 0 !important;
  margin: 0 !important;
}
.activities-add .ad-layout-side {
  flex: 0 0 250px;
  max-width: 250px;
  min-width: 250px;
  padding: 1.5rem 1.5rem 80px 1.5rem;
  position: relative;
  background-color: #e9ecef;
}
.activities-add .ad-layout-content {
  padding: 1.5rem 1.5rem 80px 1.5rem;
  width: 750px;
  height: calc(100vh - 100px);
  overflow-x: auto;
}
.activities-add .ad-layout .ant-steps .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #2f2a64;
}
.activities-add .ad-layout .ant-steps .ant-steps-item-description {
  padding-bottom: 2rem;
}
.activities-add .ad-layout .activity-detail-section {
  padding: 1rem 0.5rem;
  margin: 1rem -0.5rem;
  background-color: #e9ecef;
}
.activities-add .ad-layout .ad-parameters {
  table-layout: fixed;
  margin-top: 1rem;
}
.activities-add .ad-layout .ad-parameters .ant-avatar {
  background-color: #49409A;
}
.activities-add .ad-layout .ad-parameters .list-table-row .list-table-col {
  border-top: 1px solid #e9ecef;
  border-bottom: 1px solid #e9ecef;
  padding: 0.3rem 1rem 0.3rem 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}
.activities-add .ad-layout .ad-parameters .list-table-row .list-table-col:first-child {
  padding-left: 0.5rem;
  border-left: 1px solid #e9ecef;
}
.activities-add .ad-layout .ad-parameters .list-table-row .list-table-col:last-child {
  border-right: 1px solid #e9ecef;
}
.activities-add .ad-layout .ad-parameters .list-table-row .list-table-col.ad-col-avatar {
  width: 50px !important;
}
.activities-add .ad-layout .ad-parameters .list-table-row .list-table-col.ad-col-input {
  width: 300px !important;
  padding-top: 0.6rem;
}
.activities-add .ad-layout .ad-parameters .list-table-row .list-table-col.ad-col-input .ant-input-group-addon {
  background-color: #e9ecef;
  text-align: left;
}
.activities-add .ad-layout .ad-parameters .list-table-row .list-table-col.ad-col-input .ant-input-wrapper .ant-input-group-addon:first-child {
  width: 80px;
}
.activities-add .ad-layout .ad-parameters .list-table-row .list-table-col.ad-col-input .ant-input-wrapper .ant-input-group-addon:last-child {
  width: 80px;
}
.activities-add .ad-layout .set-controls .sc-item {
  border-top: 1px solid #e9ecef;
}
.activities-add .ad-layout .set-controls .sc-item:first-child {
  border-top: none;
}
.activities-add .ad-layout .set-controls .sc-item .sc-item-switch {
  padding: 1.2rem 1rem;
}
.activities-add .ad-layout .set-controls .sc-wf-panel {
  padding: 1rem 3rem;
  background-color: #e9ecef;
}
.activities-add .ad-layout .modify-states {
  position: relative;
}
.activities-add .ad-layout .modify-states-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: #fff;
}
.activities-add .ad-layout .modify-states-body {
  top: 75px;
  position: relative;
  height: calc(100vh - 220px);
  overflow-x: auto;
  padding-right: 1rem;
}
.activities-add .ad-layout .modify-states .ant-tree .ant-tree-treenode {
  padding: 0.2rem 0;
}
.activities-add .ad-layout .modify-states .ant-tree .check-all {
  margin-bottom: 0.5rem;
}
.activities-add .ad-layout .modify-states .ant-tree .check-all .ant-tree-switcher {
  visibility: hidden;
}
.activities-add .ad-layout .modify-states .ant-tree .ms-parent {
  background-color: #e9ecef;
  padding: 0.5rem 0;
  margin: 0.2rem 0;
  width: 100%;
}
.activities-add .ad-layout .modify-states .ant-tree .ant-tree-indent-unit {
  width: 20px;
}
.activities-add .ad-layout .execution-profile .ep-add {
  background-color: #f8f9fa;
  padding: 1rem 2rem 2rem 2rem;
}
.activities-add .ad-layout .execution-profile .radio-list-wrap {
  border-top: 1px solid #e9ecef;
}
.activities-add .ad-layout .execution-profile .radio-list-wrap:first-child {
  border-top: none;
}
.activities-add .ad-layout .execution-profile .radio-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0.5rem 0.8rem 2rem;
  cursor: pointer;
}
