.new-court .relevant-postal-codes {
  border: 1px solid #dee2e6;
  padding: 0.5rem;
  border-radius: 2px;
}
.new-court .relevant-postal-codes .rpc-wrap {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 170px;
  padding-right: 5px;
}
.new-court .relevant-postal-codes .rpc-wrap::-webkit-scrollbar {
  width: 4px;
}
.new-court .relevant-postal-codes .rpc-item {
  display: flex;
  align-items: center;
  padding: 0.2rem 0;
  border-bottom: 1px solid #e9ecef;
}
.new-court .relevant-postal-codes .rpc-item .rpc-title {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 8px;
}
.new-court .relevant-postal-codes .rpc-item .rpc-icon {
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.manage-court-types .court-types-list {
  border: 1px solid #dee2e6;
  overflow-y: auto;
  border-radius: 2px;
  max-height: calc(100vh - 240px);
}
.manage-court-types .court-types-list .ctl-item {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #dee2e6;
}
.manage-court-types .court-types-list .ctl-item:last-child {
  border-bottom: none;
}
.manage-court-types .court-types-list .ctl-item .ctl-title {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 1rem;
}
.manage-court-types .court-types-list .ctl-item .ctl-actions {
  display: flex;
  align-items: center;
}
.manage-court-types .court-types-list .ctl-item-edit {
  background-color: #e9ecef;
  display: block;
}
.manage-court-types .court-types-list .ctl-item-edit .ctl-item-edit-inner {
  position: relative;
}
.manage-court-types .court-types-list .ctl-item-edit .ctl-item-edit-inner .ant-form-item {
  margin-bottom: 0;
}
.manage-court-types .court-types-list .ctl-item-edit .ctl-item-edit-inner .ant-form-item input {
  padding-right: 2rem;
}
.manage-court-types .court-types-list .ctl-item-edit .ctl-item-edit-inner button {
  position: absolute;
  top: 4px;
  right: 0;
  z-index: 1;
}
