.us-mega-menu .mega-menu-wrapper {
  display: flex;
  flex-direction: row;
  flex: auto;
  min-height: 0;
}
.us-mega-menu {
  overflow: hidden;
}
.us-mega-menu .dropbtn {
  font-size: 14px;
  border: none;
  outline: none;
  color: #fff;
  background-color: #2f2a64;
  margin: 0;
  padding-left: 20px;
  padding-right: 20px;
  transition: all 300ms;
  overflow: hidden;
  cursor: pointer;
}
.us-mega-menu .dropbtn .mega-menu-close {
  -webkit-transform: translatey(40px);
  transform: translatey(40px);
  transition: all 300ms;
}
.us-mega-menu .dropbtn:hover {
  color: #8179c7;
}
.us-mega-menu .dropbtn.selected {
  background-color: #fff;
  color: #000;
  transition: all 300ms;
}
.us-mega-menu .dropbtn.selected .mega-menu-close {
  -webkit-transform: translatey(1px);
  transform: translatey(1px);
}
.us-mega-menu .mega-menu-content {
  position: absolute;
  width: auto;
  left: 0;
  top: 48px;
  transition: 0.4s ease-in-out;
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: all 300ms;
  box-shadow: 30px 20px 40px -21px rgba(0, 0, 0, 0.26);
  z-index: 1030;
}
.us-mega-menu .mega-menu-content.show {
  opacity: 1;
  height: auto;
}
.us-mega-menu .mega-menu-wrapper {
  height: calc(100vh - 48px);
  background-color: rgba(17, 16, 31, 0.205);
}
.us-mega-menu .mega-menu-wrapper-fixed-left {
  border-right: 1px solid #fff;
  flex: 0 0 300px;
  max-width: 300px;
  min-width: 300px;
  height: calc(100vh - 48px);
  background-color: #fff;
  padding: 1rem 1.5rem;
  overflow: auto;
}
.us-mega-menu .mega-menu-wrapper-modulebg {
  flex: 0 0 250px;
  max-width: 250px;
  min-width: 250px;
  background-color: #f4f3fa;
  border-right: transparent none;
  padding: 1rem 0rem;
}
.us-mega-menu .mega-menu-wrapper-featurebg {
  flex: 0 0 300px;
  max-width: 300px;
  min-width: 300px;
  background-color: #edecf7;
  border-right: transparent none;
  padding: 1rem 0rem;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  scrollbar-width: thin;
  scrollbar-color: #49409A #edecf7;
}
.us-mega-menu .mega-menu-wrapper .mg-menu-icon-outer {
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
  color: #fff;
  padding: 0.2rem;
  margin-right: 0.5rem;
  background-color: #45BD63;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border-radius: 0.2rem;
}
.us-mega-menu .mega-menu-wrapper .module-menu-wrapper a {
  padding: 0.3rem 1.5rem;
  transition: all 0.3s;
  margin-bottom: 1px;
  font-weight: 700;
}
.us-mega-menu .mega-menu-wrapper .module-menu-wrapper a .module-icon-outer {
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  color: #fff;
  padding: 0.3rem;
  margin-right: 0.5rem;
  background-color: #49409A;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border-radius: 0.2rem;
}
.us-mega-menu .mega-menu-wrapper .module-menu-wrapper a:hover {
  background-color: #edecf7;
}
.us-mega-menu .mega-menu-wrapper .module-menu-wrapper a.active {
  background-color: #edecf7;
}
.us-mega-menu .mega-menu-wrapper .feature-menu-wrapper .feature-group {
  color: #000;
  font-weight: 700;
  margin-left: 0.5rem;
}
.us-mega-menu .mega-menu-wrapper .feature-menu-wrapper .ant-collapse-content-box {
  padding-top: 0px;
}
.us-mega-menu .mega-menu-wrapper .feature-menu-wrapper .feature-link-item {
  padding: 0.2rem 0.1rem 0.2rem 0.5rem;
  border-radius: 3px;
  transition: all 0.3s;
}
.us-mega-menu .mega-menu-wrapper .feature-menu-wrapper .feature-link-item a {
  line-height: 2;
  color: #49409A;
}
.us-mega-menu .mega-menu-wrapper .feature-menu-wrapper .feature-link-item:hover {
  background-color: #c4c1e5;
  padding: 0.2rem 0.3rem 0.2rem 0.5rem;
}
.us-mega-menu .mega-menu-wrapper .feature-menu-wrapper .feature-link-item:hover a {
  color: #49409A;
  line-height: 2;
}
.us-mega-menu .mega-menu-wrapper .feature-menu-wrapper .feature-link-item .ant-btn-link {
  color: #6c757d;
  font-size: 0.7rem;
  padding: 0;
}
.us-mega-menu .mega-menu-wrapper .feature-menu-wrapper .feature-link-item .ant-btn-link:hover {
  color: #343a40;
}
.us-mega-menu .mega-menu-wrapper .quick-link-wrap .quick-link-item {
  padding: 0.2rem 0.1rem 0.2rem 0rem;
  border-radius: 3px;
  transition: all 0.3s;
  line-height: 2;
  cursor: pointer;
}
.us-mega-menu .mega-menu-wrapper .quick-link-wrap .quick-link-item a {
  color: #49409A;
}
.us-mega-menu .mega-menu-wrapper .quick-link-wrap .quick-link-item:hover {
  background-color: #edecf7;
  padding: 0.2rem 0.3rem 0.2rem 0.3rem;
}
.us-mega-menu .mega-menu-wrapper .quick-link-wrap .quick-link-item:hover a {
  color: #49409A;
  line-height: 2;
}
.us-mega-menu .mega-menu-wrapper .quick-link-wrap .quick-link-item .ant-btn-link {
  color: #49409A;
  font-size: 0.7rem;
  padding: 0;
}
.us-mega-menu .mega-menu-wrapper .quick-link-wrap .quick-link-item .ant-btn-link:hover {
  color: #343a40;
}
.us-mega-menu .us-mega-menu-row {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 40px;
}
.us-mega-menu .us-mega-menu-column {
  flex: 20% 1;
  padding: 10px;
}
.us-mega-menu .us-mega-menu-column .menu-title {
  font-weight: 700;
  padding-bottom: 5px;
  border-bottom: 1px solid #ccc;
}
.us-mega-menu .us-mega-menu-column a {
  float: none;
  color: #49409A;
  padding: 5px;
  text-decoration: none;
  display: block;
  text-align: left;
  line-height: normal;
}
.us-mega-menu .us-mega-menu-column a:hover {
  text-decoration: underline;
  color: #8179c7;
}
.us-mega-menu .menu-footer {
  margin: 0 -10px;
}
.us-mega-menu .menu-footer div {
  line-height: normal;
  line-height: initial;
}
.us-mega-menu .menu-footer .foot-link {
  line-height: 1.5;
}
.us-mega-menu .menu-footer .foot-link a {
  color: #6c757d;
  line-height: 1.5;
}
.us-mega-menu .menu-footer .foot-link a:hover {
  text-decoration: underline;
}
