.non-production-days .current-year {
  background-color: #edecf7;
  border: 1px solid #edecf7;
}
.non-production-days .ant-table-thead > tr > th {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  border: none;
}
.non-production-days .ant-table-thead > tr > th::before {
  display: none;
}
.non-production-days .ant-table-tbody > tr > td {
  border-bottom: none;
}
.non-production-days .inline-calendar {
  width: 300px;
  border: 1px solid #e9ecef;
  border-radius: 2px;
}
.non-production-days .inline-calendar .ant-picker-calendar-mode-switch {
  display: none;
}
.non-production-days .right-panel {
  border-left: 1px solid #e9ecef;
  position: fixed;
  right: 20px;
  top: 75px;
  bottom: 30px;
  width: 33.33%;
  max-height: calc(100vh - 105px);
  overflow-y: auto;
  padding-top: 15px;
  z-index: 99;
}
.non-production-days .empty-calendar {
  text-align: center;
  padding: 4rem 0;
}
.non-production-days .year-pick {
  position: absolute;
  max-width: 1px;
  max-height: 1px;
  overflow: hidden;
  opacity: 0;
  margin-top: -28px;
  margin-left: -15px;
}
.non-production-days .non-sms-days .ant-collapse-header {
  font-weight: 500;
}
