.container {
  flex: 1 1;
  flex-direction: column;
  min-height: 400px;
  text-align: center;
  padding: 150px 0;
}
.details {
  text-align: center;
}
.message {
  margin-bottom: 24px;
  text-align: center;
}
